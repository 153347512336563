// Import Font Awesome
import "@fortawesome/fontawesome-free/js/fontawesome.min";
import "@fortawesome/fontawesome-free/js/solid.min";
import "@fortawesome/fontawesome-free/js/regular.min";
import "@fortawesome/fontawesome-free/js/brands.min";

// Initialize the header functionality
function initHeader(el) {
  const setupMegaMenu = () => {
    const menuBtn = el.querySelector(".menu-btn");
    const menuItems = el.querySelector(".menu-items");
    menuBtn.addEventListener("click", (e) => {
      e.stopPropagation();
      toggle();
    });

    window.onkeydown = (event) => {
      const key = event.key; // const {key} = event; in ES6+
      const active = menuItems.classList.contains("open");
      if (key === "Escape" && active) {
        toggle();
      }
    };

    document.addEventListener("click", (e) => {
      let target = e.target,
        its_menu = target === menuItems || menuItems.contains(target),
        its_hamburger = target === menuBtn,
        menu_is_active = menuItems.classList.contains("open");
      if (!its_menu && !its_hamburger && menu_is_active) {
        toggle();
      }
    });

    menuItemMobile();
    menuSubItemMobile();

    if (window.innerWidth > 961) {
      menuSubItemDesktop();
    }
  };

  const menuSubItemDesktop = () => {
    const divElement = el.querySelectorAll(
      ".mega-menu_sub .mega-menu_sub-content"
    );
    const megaMenus = el.querySelectorAll(
      ".mega-menu_sub .mega-menu_sub-item"
    );
    const mainMenus = el.querySelectorAll(".menu-items__megamenu");
    console.log(mainMenus);

    let maxBlockHeight = -1;

    divElement.forEach(function (item) {
      if (item.scrollHeight > maxBlockHeight) {
        maxBlockHeight = item.scrollHeight;
      }
    });

    mainMenus.forEach(function (item) {
      item.addEventListener("mouseenter", function (e) {
        e.preventDefault();

        this.classList.add("active-menu");
        const megaMenuItem = this.querySelector(".mega-menu_sub-item");
        if (megaMenuItem !== null) {
          megaMenuItem.classList.add("hover-active");
          megaMenuItem.querySelector(
            ".mega-menu_sub-content"
          ).style.visibility = "visible";
          this.querySelector(".menu-items__submenu").style.height =
            maxBlockHeight + "px";
          megaMenuItem.querySelector(".mega-menu_sub-content").style.height =
            maxBlockHeight + "px";
        }
      });
      item.addEventListener("mouseleave", function (e) {
        e.preventDefault();
        if (window.currentMenuItem) {
          let menuItem = window.currentMenuItem;
          menuItem.classList.remove("hover-active");
          menuItem.children[0]?.classList.remove("active-nav");
          const prevEl = menuItem?.closest(".menu-items__megamenu-submenu");
          prevEl.style.height = "auto";
          menuItem.querySelector(".mega-menu_sub-content").style.visibility =
            "hidden";
        }
        this.classList.remove("active-menu");
        const megaMenuItem = this.querySelector(".mega-menu_sub-item");
        if (megaMenuItem !== null) {
          megaMenuItem.classList.remove("hover-active");
          megaMenuItem.querySelector(
            ".mega-menu_sub-content"
          ).style.visibility = "hidden";
          this.querySelector(".menu-items__submenu").style.height = "auto";
          megaMenuItem.querySelector(".mega-menu_sub-content").style.height =
            "auto";
        }
      });
    });

    megaMenus.forEach(function (item) {
      item.addEventListener("mouseenter", function (e) {
        e.preventDefault();
        this.classList.add("hover-active");
        const prevEl = this.closest(".menu-items__megamenu-submenu");
        prevEl.style.height = maxBlockHeight + "px";
        this.querySelector(".mega-menu_sub-content").style.visibility =
          "visible";
      });
      item.addEventListener("mouseleave", function (e) {
        e.preventDefault();
        // Bug-3634
        if (e?.relatedTarget?.parentNode?.parentNode?.className === "menu-items") {
          return;
        }
        // BUG-3698
        if (e?.relatedTarget.className === 'mega-menu_sub') {
          window.currentMenuItem = this;
          return;
        }
        this.classList.remove("hover-active");
        this.children[0]?.classList.remove("active-nav");
        const prevEl = this.closest(".menu-items__megamenu-submenu");
        prevEl.style.height = "auto";
        this.querySelector(".mega-menu_sub-content").style.visibility =
          "hidden";
      });
    });
  };

  const menuItemMobile = () => {
    const expandBtn = el.getElementsByClassName("expand-btn");
    const SubItemPanel = el.getElementsByClassName("menu-items__submenu");
    const btnsWithIsOpen = el.getElementsByClassName("expand-btn is-open");
    const menusSelected = el.getElementsByClassName(
      "menu-items__megamenu menu-selected"
    );
    const openClass = "is-open";
    const openLIClass = "menu-selected";

    menuItemOpen(
      expandBtn,
      SubItemPanel,
      btnsWithIsOpen,
      openClass,
      openLIClass,
      menusSelected
    );
  };

  const menuSubItemMobile = () => {
    const expandSubItems = el.getElementsByClassName("expand-subitem-btn");
    const SubItemPanel = el.getElementsByClassName(
      "mega-menu_sub-content"
    );
    const SubItemOpen = el.getElementsByClassName(
      "expand-subitem-btn is-itemOpen"
    );
    const menusSelected = el.getElementsByClassName(
      "mega-menu_sub-item active-nav"
    );
    const openClass = "is-itemOpen";
    const openLIClass = "active-nav";

    menuItemOpen(
      expandSubItems,
      SubItemPanel,
      SubItemOpen,
      openClass,
      openLIClass,
      menusSelected
    );
  };

  const menuItemOpen = (
    expandSubItems,
    SubItemPanel,
    SubItemOpen,
    openClass,
    openLIClass,
    menusSelected
  ) => {
    let i;

    for (i = 0; i < expandSubItems.length; i++) {
      expandSubItems[i].addEventListener("click", function (e) {
        e.stopPropagation();
        var panel = this.nextElementSibling;
        console.log("panel", this, this.parentElement);
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
          this.classList.remove(openClass);
          this.parentElement.classList.remove(openLIClass);
        } else {
          for (var ii = 0; ii < SubItemOpen.length; ii++) {
            SubItemOpen[ii].classList.remove(openClass);
          }
          for (var j = 0; j < menusSelected.length; j++) {
            menusSelected[j].classList.remove(openLIClass);
          }
          for (var iii = 0; iii < SubItemPanel.length; iii++) {
            this.classList.remove(openClass);
            SubItemPanel[iii].style.maxHeight = null;
          }
          if (panel) {
            panel.style.maxHeight = panel.scrollHeight + "px";
            const prevHeight = parseInt(
              panel.closest(".menu-items__submenu").style.maxHeight,
              10
            );
            panel.closest(".menu-items__submenu").style.maxHeight =
              panel.scrollHeight + prevHeight + "px";
          }
          this.classList.add(openClass);
          this.parentElement.classList.add(openLIClass);
        }
      });
    }
  };

  const toggle = () => {
    const body = document.querySelector("body");
    const menuBtn = el.querySelector(".menu-btn");
    const menuItems = el.querySelector(".menu-items");

    body.classList.toggle("menu-open");

    menuBtn.classList.toggle("open");
    menuItems.classList.toggle("open");
  };

  const stickyHeader = () => {
    var header = document.querySelector(".header");
    var headerHeight = header.clientHeight;

    window.addEventListener("scroll", function () {
      if (window.pageYOffset < headerHeight && window.pageYOffset !== 0) {
        console.log(
          "dd",
          window.pageYOffset !== 0,
          window.pageYOffset < headerHeight
        );
        header.style.top = -60 + "px";
      } else {
        header.style.top = 0 + "px";
      }

      if (window.pageYOffset >= headerHeight) {
        header.style.top = 0 + "px";
        header.classList.add("fixed");
      } else {
        header.classList.remove("fixed");
      }
    });
  };

  const setupSearchUI = () => {
    // Get the search button, search wrapper, search wrapper close button, and body element
    const searchButton = el.querySelector(".nav-search_button");
    const searchWrapper = el.querySelector(".search_wrapper");
    const searchWrapperClose = el.querySelector(
      ".search_wrapper .search-icon_close"
    );

    // // Create and append overlay element as we don't control the theme markup
    const overlay = document.createElement("div");
    overlay.classList.add("search-overlay");
    overlay.setAttribute("aria-modal", true);
    overlay.setAttribute("tabindex", "-1");
    searchWrapper.after(overlay);

    // Add event handler to search button to toggle the search-bar-active class, aria-hidden attribute, tabindex and overlay
    searchButton.addEventListener("click", () => {
      if (searchWrapper.classList.contains("search-bar-active")) {
        const searchInput = el.querySelector(".search-input");
        searchWrapper.classList.remove("search-bar-active");
        searchWrapper.setAttribute("aria-hidden", true);
        searchWrapper.removeAttribute("tabindex"); // remove tabindex when search bar is inactive
        overlay.classList.remove("visible");
        searchInput.focus();
      } else {
        searchWrapper.classList.add("search-bar-active");
        searchWrapper.setAttribute("aria-hidden", false);
        searchWrapper.setAttribute("tabindex", "0"); // add tabindex when search bar is active
        overlay.classList.add("visible");
      }
    });

    // Add event handler to search wrapper close button to remove the search-bar-active class, aria-hidden attribute, and overlay
    searchWrapperClose.addEventListener("click", () => {
      searchWrapper.classList.remove("search-bar-active");
      searchWrapper.setAttribute("aria-hidden", true);
      overlay.classList.remove("visible");
    });

    // Add keyboard accessibility to search button and search wrapper close button
    searchButton.addEventListener("keydown", (event) => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        event.preventDefault();
        searchButton.click();
      }
    });

    searchWrapperClose.addEventListener("keydown", (event) => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        event.preventDefault();
        searchWrapperClose.click();
      }
    });
  };

  const checkMinLength = () => {
    const serachInput = el.querySelector(".search-input");
    const inputError = el.querySelector(".input-error");
    // const serachCon = el.querySelector(".search-container");
    let ele = document.createElement("p");
    ele.classList.add("error");
    // serachInput.addEventListener("keypress", (event) => {
    if (serachInput.value.length < 3) {
      inputError.classList.add("d-block");
      inputError.classList.remove("d-none");
      // if (event.which == "13") {
      //   event.preventDefault();
      // }
      // ele.innerHTML = "please enter minimum 3 character";
      // serachCon.after(ele);
      return false;
    } else {
      inputError.classList.remove("d-block");
      inputError.classList.add("d-none");
      return true;
    }
    // });
  };

  const redirectSearch = () => {
    let searchForm = el.querySelector(".form-searchform");
    let formAction = searchForm.dataset.action;
    let searchInput = el.querySelector(".search-input");

    searchForm.addEventListener("keydown", (event) => {
      console.log(searchForm, formAction, searchInput);
      // if not 'enter key' just exit here
      if (event.keyCode === 13) {
        if (checkMinLength()) {
          let searchKeyword = searchInput?.value;
          if (formAction.includes("?")) {
            searchForm.action = formAction + "&s=" + searchKeyword;
          } else {
            searchForm.action = formAction + "?s=" + searchKeyword;
          }
          window.location.href = searchForm.action;
        }
      }
    });

    // searchForm.addEventListener("submit", (e) => {
    //   e.preventDefault();
    //   let searchKeyword = searchInput?.value;
    //   if (formAction.includes("?")) {
    //     searchForm.action = formAction + "&s=" + searchKeyword;
    //   } else {
    //     searchForm.action = formAction + "?s=" + searchKeyword;
    //   }
    //   window.location.href = searchForm.action;
    // });
  };

  setupMegaMenu();
  stickyHeader();
  setupSearchUI();
  redirectSearch();
}

// Initialize the header when the document is ready
document.addEventListener("DOMContentLoaded", () => {
  const headerElement = document.querySelector('.header');
  if (headerElement) {
    initHeader(headerElement);
  }
});
